<template>
  <div class="login_wrap">
    <van-form>
      <div class="login_bg">
        <van-image class="login_bg_li" :src="bg"></van-image>
        <div class="logins_top">
          <!-- <van-image class="logins_top_img" :src="logo" /> -->
          <span class="login_text">瑞方人力</span>
        </div>
      </div>
      <div class="login_outer">
        <div class="login_top">登录</div>
        <div class="login_con">欢迎登陆51入职</div>
        <div class="login_form">
          <van-field
            border
            v-model="mobile"
            name="mobile"
            center
            type="tel"
            clearable
            maxlength="11"
            placeholder="请输入手机号码"
          ></van-field>
          <van-field
            v-model="code"
            name="code"
            center
            type="digit"
            clearable
            maxlength="4"
            placeholder="请输入短信验证码"
            class="login_bottom_field"
          >
            <template #button>
              <van-button
                size="large"
                type="info"
                @click="sendCode"
                :disabled="smsFlag"
                :style="{sendColor }"
                >{{ sendTime }}</van-button
              >
            </template>
          </van-field>
        </div>
        <div class="login_bottom">
          <van-button size="large" type="info" @click="toMyinfo"
            >提交</van-button
          >
        </div>
      </div>
    </van-form>
    <!-- <van-overlay :show="isShow"
      ><div class="wrapper">
        <div class="block">拒绝访问！</div>
      </div>
    </van-overlay> -->
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      code: "",
      mobile: "",
      logo: require("../assets/images/logo.png"),
      bg: require("../assets/images/loginbg.png"),
      sendTime: "获取验证码", //发送验证码
      snsMsgWait: 60, //请求时间
      smsFlag: false, //发送验证码
      sendColor: "color:#fff;background-color:#0079FE;", //按钮颜色
      verifkey: "",
      key: "",
      access_token: "",
      pid: "",
      pnid:"",
      // isShow: false,
    };
  },
  components: {},
  mounted() {
    let param = window.location.search;
    this.pnid = param.substring(param.lastIndexOf("=")+1);
    localStorage.setItem("pnid", this.pnid);
    // if (!this.pid) {
    //   this.$dialog.alert({
    //     message: "拒绝访问！",
    //     showConfirmButton:false,
    //   }).then(() => {
    //   });
    // }
  },
  methods: {
    async sendCode() {
      //发送验证码
      if (!/^[1][3,4,5,7,8][0-9]{9}$/.test(this.mobile)) {
        this.$toast({ message: "请输入正确的手机号码" });
        return false;
      } else {
        // 60秒后重新获取验证码
        var inter = setInterval(
          function () {
            this.smsFlag = true;
            this.sendColor = "color:#fff;background-color:#cccccc";
            this.sendTime = this.snsMsgWait + "s后重发";
            this.snsMsgWait = this.snsMsgWait - 1;
            if (this.snsMsgWait < 0) {
              clearInterval(inter);
              this.sendColor = "color:#fff;background-color:#0079FE";
              this.sendTime = "重新获取验证码";
              this.snsMsgWait = 60;
              this.smsFlag = false;
            }
          }.bind(this),
          1000
        );
      }
      this.$axios
        .get("/sms/directsendsms?mobile=" + this.mobile)
        .then((res) => {
          if (res.data.success) {
            this.$toast({ message: res.data.msg });
            this.verifkey = res.data.data.key;
          } else {
            this.$toast({ message: res.data.msg });
          }
        })
        .catch(() => {});
    },
    //登录
    toMyinfo() {
      let param = {};
      param.mobile = this.mobile;
      param.code = this.code;
      param.verifkey = this.verifkey;
      localStorage.setItem("mobile", this.mobile);
      this.$axios
        .post("/user/edit", param, {})
        .then((res) => {
          if (res.data.success) {
            this.$toast({ message: "登录成功" });
            this.access_token = res.data.data.access_token;
            localStorage.setItem("access_token", this.access_token);
            localStorage.setItem("mobile", this.mobile);
            if (res.data.data.pid) {
              localStorage.setItem("id", res.data.data.id);
              localStorage.setItem("pid", res.data.data.pid);
            } else {
              localStorage.setItem("id", "");
              localStorage.setItem("pid", "");
            }
            this.$router.push({name:'welcome'})
            // this.getPhotoInfo();
          } else {
            this.$toast({ message: res.data.msg });
          }
        })
        .catch(() => {});
      // 测试时，先把手机号省略
      // localStorage.setItem("mobile", 17521242348);
      // localStorage.setItem("id", 35);
      // localStorage.setItem("pid", 57524);
      // this.access_token =
      //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJqd3RfeWgiLCJleHAiOjE2NDYxMjc1MDMsInN1YiI6IllIIiwiYXVkIjoiZXZlcnkiLCJuYmYiOjE2NDU1MjI3MDMsImlhdCI6MTY0NTUyMjcwMywianRpIjoxMDAwMSwidWlkIjozNX0.HinP5YGaYPwum7v_FJmgSUV1hy6uqMT6131Eyt9it5g";
      // localStorage.setItem("access_token", this.access_token);
      // this.$router.push({name:'welcome'})
      // this.getPhotoInfo();
    },
    //获取个人信息
    // getPhotoInfo() {
    //   let pid = localStorage.getItem("pid");
    //   this.$axios
    //     .get(
    //       "/tag/info?table=person&table2=person_salary&fieldone=id&fieldtwo=person_id&id= " +
    //         pid +
    //         '&fieldasname={"name":"username","card_code":"usercard_code","phone":"phone","address":"address","pic":"pic","data_status":"data_statusb"}'
    //     )
    //     .then((res) => {
    //       if (res.data.success) {
    //         let returnData = res.data.data;
    //         if (
    //           returnData.username &&
    //           returnData.usercard_code &&
    //           returnData.bank_account &&
    //           returnData.bank_title &&
    //           returnData.address &&
    //           returnData.pic.split(",")[0] &&
    //           returnData.pic.split(",")[1]
    //         ) {
    //           this.$router.push({ name: "confirm" });
    //         } else {
    //           this.$router.push({ name: "myInfo" });
    //         }
    //       } else {
    //         this.$toast({ message: res.data.msg });
    //       }
    //     })
    //     .catch(() => {});
    // },
  },
};
</script>
<style scoped>

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 200px;
  height: 200px;
  background-color: #fff;
}
.login_bottom_field {
  border-bottom: 0.0625rem solid #eee;
}
.login_outer {
  /* padding-top: 10rem; */
  box-sizing: border-box;
  text-align: left;
  width: calc(100% - 3rem);
  margin: 0 auto;
}
.login_top {
  font-weight: bold;
  line-height: 2rem;
  font-size: 1.2rem;
}
.login_con {
  font-size: 0.9rem;
  color: rgba(51, 51, 51, 0.6);
  line-height: 2rem;
}
.login_bg {
  position: relative;
}
.login_bg_li {
  width: 100%;
  padding:0.5rem;
  box-sizing: border-box;
  height: 16.25rem;
}
.logins_top {
  margin: 0 auto;
  text-align: center;
  position: absolute;
  top: 70%;
  left: 30%;
  /* width: 6rem; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logins_top_img {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  overflow: hidden;
}
.login_text{
  margin-left: 2rem;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #4E7DE8;
  font-weight: bold;
}
</style>
<style>
.login_bottom_field .van-field__button{
  margin-left:1rem;
}
.login_form .van-field__clear{
  font-size:1rem;
}
.van-toast__text {
  line-height: 2rem !important;
  font-size: 1rem !important;
  text-align: center;
}

.login_form {
  margin-top: 2rem;
}
.login_form .van-cell {
  line-height: 2.4rem;
  font-size: 1rem;
}
.login_form .van-button {
  border-radius: 1rem;
  padding: 1rem;
}
.login_bottom {
  margin-top: 2rem;
}
.login_bottom .van-button {
  font-size: 1rem;
  height: 2rem;
  border-radius: 0.5rem;
}
.login_form .van-cell::after {
  border-bottom: 0.03rem solid #ebedf0;
}
</style>

